<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
              <v-spacer></v-spacer>
              <template>
                <download-excel :data="fineData" :fields="all_org" worksheet="Books Data" name="Books Data.xls">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                    </template>
                    <span>Import to Excel</span>
                  </v-tooltip>
                </download-excel>
              </template>
            </v-card-title>
          
            <v-data-table :headers="headers" :items="fineData" :search="search">
              <template v-slot:item.fine="{ item }">
                <v-icon>mdi-currency-inr</v-icon><span style="font-size:18px">{{ item.fine }}</span>
              </template>
            </v-data-table>
           
          </v-card> </div>
        </template>
      </div>
    </section>
    <!-- /. section -->
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    init_loading: false,
    fineData: [],
    orgbookdetails: [],
    loading: true,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    search_book: "",
    org_name: null,
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Management Dashboard',
        disabled: false,
        href: '/management-dashboard',
      },
      {
        text: 'Fine Collected',
        disabled: true,
        href: '',
      },
    ],
    dialog_details: false,
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "Organization", value: "orgName" },
      { text: "Fine collected", value: "fine" },
    ],
    all_org: {
      "Sr. No.": "srno",
      Orgnization: "orgName",
      "Total Fine in Rs.": "fine",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.init_loading = true
      axios
        .post("/ManagementAdmin/getAllOrgFineData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.fineData = res.data.fineData;
            this.init_loading = false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1024px) {}
</style>
